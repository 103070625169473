import React, { Component } from 'react';
import Storage from '../../classes/storage.class.js';
import { Redirect } from 'react-router';

class Logout extends Component {

  constructor() {

    // go
    super();

    // set
    Storage.set('AuthToken', null);

  }


  render() {

    return <Redirect to='/' />;

  }

}

export default Logout;