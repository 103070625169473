import Loadable from '@loadable/component'
import DefaultLayout from './containers/DefaultLayout';

// logout
import Logout from './views/Logout';

const Dashboard = Loadable(() => import('./views/Dashboard'));
const UserOverview = Loadable(() => import('./views/Users/Overview'));
const UserEdit = Loadable(() => import('./views/Users/Edit'));
const TemplateOverview = Loadable(() => import('./views/Templates/Overview'));
const TemplateEdit = Loadable(() => import('./views/Templates/Edit'));
const TemplateCreate = Loadable(() => import('./views/Templates/Create'));
const CodeIndexOverview = Loadable(() => import('./views/CodeIndex/Overview'));
const CustomerOverview = Loadable(() => import('./views/Customers/Overview'));
const CustomerEdit = Loadable(() => import('./views/Customers/Edit'));
const CustomerCreate = Loadable(() => import('./views/Customers/Create'));
const FlicOverview = Loadable(() => import('./views/Flic/Overview'));
const FlicCreate = Loadable(() => import('./views/Flic/Create'));
const FlicEdit = Loadable(() => import('./views/Flic/Edit'));
const TasksManageProcessTemplateOverview = Loadable(() => import('./views/Tasks/ProcessTemplates/Overview'));
const TasksManageProcessTemplateEdit = Loadable(() => import('./views/Tasks/ProcessTemplates/Edit'));
const TasksManageProcessTemplateCreate = Loadable(() => import('./views/Tasks/ProcessTemplates/Create'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', name: 'Home', component: DefaultLayout, exact: true },
  { path: '/customers/overview', name: 'Customer Overview', component: CustomerOverview },
  { path: '/customers/create', name: 'Create Customer', component: CustomerCreate },
  { path: '/customers/edit/:customer', name: 'Edit Customer', component: CustomerEdit },
  { path: '/codeindex/overview', name: 'Code Index Overview', component: CodeIndexOverview },
  { path: '/users/overview', name: 'User Overview', component: UserOverview },
  { path: '/users/edit/:customer/:id', name: 'Edit User', component: UserEdit },
  { path: '/templates/overview', name: 'Template Overview', component: TemplateOverview },
  { path: '/templates/create', name: 'Create Template', component: TemplateCreate },
  { path: '/templates/edit/:id', name: 'Edit Template', component: TemplateEdit },
  { path: '/connect/overview', name: 'X-Guard Connect Overview', component: FlicOverview },
  { path: '/connect/create', name: 'Add new X-Guard Connect', component: FlicCreate },
  { path: '/connect/edit/:customer/:id', name: 'Edit X-Guard Connect', component: FlicEdit },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/tasks/process/overview', name: 'Alarm Process Overview', component: TasksManageProcessTemplateOverview },
  { path: '/tasks/process/edit/:id', name: 'Edit Alarm Process', component: TasksManageProcessTemplateEdit },
  { path: '/tasks/process/create', name: 'Create Alarm Process', component: TasksManageProcessTemplateCreate },
  { path: '/session/logout', name: 'Logout',  exact: true, component: Logout },
];

export default routes;