import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

import Api from '../../classes/api.class.js';
import Storage from '../../classes/storage.class.js';

class Login extends Component {

  constructor(){

    // go
    super();

    // init
    this.state = {
      username: "",
      password: "",
      authenticated: null,
      redirect: null,
      error: false,
    }

  }


  async login(e) {

    // prevent default
    e.preventDefault();

    // get formdata
    const data = new FormData(e.target);

    // do login
    const r = await Api.post('/login', {
      token: true,
      username: data.get('username'),
      password: data.get('password')
    }, 2);

    // check statuscode
    let authenticated = r.result.status === 200;

    // check if service provider
    if(authenticated !== true){

      return this.setState({
        error: true
      });

    }

    // check if service provider
    if(r.returnData.flags.isServiceProvider !== true){

      return this.setState({
        error: true
      });

    }

    // save
    Storage.set('AuthToken', r.returnData.token);

  }

  render() {

    // default component
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="5">
              <CardGroup>
                <Card className="p-2">
                  <CardBody>
                    <form className="needs-validation" onSubmit={this.login.bind(this)}>
                      <h1>Login</h1>
                      <p className="text-muted">Use your X-Guard provider account to login.</p>
                      {this.state.error === true ? <div className="alert alert-danger show">
                      Please sign in using a provider account. This account does either not exist or is not a valid service provider.
                        </div> : null}
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" id="username" name="username" className="invalid" required placeholder="Username" />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" name="password" required placeholder="Password" />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" type="submit" className="px-4">Login</Button>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
