import queryString from 'query-string';
import Storage from './storage.class.js';

class Api {

  constructor() {

    // init
    this.baseUrl = 'https://xguard.tellucloud.com';

  }

  async get(path = "/", data = {}, apiVersion = 3, pathPrefix = '/web/api'){

  	// proxy
  	return this.request("GET", path, data, apiVersion, pathPrefix);

  }

  async post(path = "/", data = {}, apiVersion = 3, pathPrefix = '/web/api'){

    // proxy
    return this.request("POST", path, data, apiVersion, pathPrefix);

  }


  async put(path = "/", data = {}, apiVersion = 3, pathPrefix = '/web/api'){

    // proxy
    return this.request("PUT", path, data, apiVersion, pathPrefix);

  }

  async delete(path = "/", data = {}, apiVersion = 3, pathPrefix = '/web/api'){

    // proxy
    return this.request("delete", path, {}, apiVersion, pathPrefix);

  }

  async request(method = "POST", path = "/", data = {}, apiVersion = 3, pathPrefix = '/web/api') {

    // check auth
    const authToken = Storage.get('AuthToken');
    let authHeader = {};
    if(authToken !== null){

      // set auth token
      authHeader = {
        'X-Auth-Token': authToken
      }

    }

    // check
    const append = {};
    if(method === 'POST' || method === 'PATCH' || method === 'PUT'){

      append.body = JSON.stringify(data)

    }

    // check get qs
    let qs = '';
    if(method === 'GET' && Object.keys(data).length > 0){

      qs = `?${queryString.stringify(data)}`;

    }

    // options
    const options = {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...authHeader,
      },
      ...append,
    };

    // fetch
    let result;
    try {

      // go time
      result = await fetch(`${this.baseUrl}${pathPrefix}${apiVersion}${path}${qs}`, options);

    } catch (e) {

      // return
      return {
        success: false,
        result: result,
        error: e
      }

    }

    // parse
    let returnData;
    try {

      returnData = await result.text();
      returnData = JSON.parse(returnData);

    } catch (e) {

      // return
      return {
        success: false,
        returnData: returnData,
        result: result,
        error: e
      }

    }

    // done
    return {
      success: true,
      returnData: returnData,
      result: result,
    }

  }

}

export default (new Api());