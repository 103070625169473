import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.css';

// Import Main styles for this application
import './scss/style.css'

// Containers
import { DefaultLayout } from './containers';

// components
import { Auth } from './components';

// classes
import Storage from './classes/storage.class.js';
import Api from './classes/api.class.js';


class App extends Component {


  constructor(){

    // go
    super();

    // init
    this.state = {
      authenticated: (Storage.get('AuthToken')) !== null,
    }

    // check authed
    if(this.state.authenticated === true){

      // get authentication verification
      this.getTelluData();

    }

    // listen
    Storage.on('update', this.checkAuthenticated, this);

  }

  async getTelluData(){

    // get the data
    const telluData = await Api.get('/');

    // mutate customers
    telluData.returnData.customers.sort((a, b) => {

      // compare the name
      if(a.name < b.name){

        return -1;

      }else
      if(a.name > b.name){

        return 1;

      }

      // same
      return 0;

    });

    // save
    Storage.set('TelluData', telluData.returnData);

  }

  async checkAuthenticated(key, value){

    // check
    if(key === 'AuthToken'){

      // get authentication verification
      await this.getTelluData();

      // init
      this.setState({
        authenticated: (Storage.get('AuthToken')) !== null,
      });

    }

  }

  render() {

    // check
    if(this.state.authenticated !== true){

      return <Auth />;

    }

    return (
      <HashRouter>
        <Switch>
          <Route path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
