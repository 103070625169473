import React, { Component } from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Login } from '../views';

class AuthClass extends Component {

  render(){

  	return (
  		<HashRouter>
        <Switch>
          <Route path="/session/login" name="Login" component={Login} />
          <Redirect to='/session/login' />
        </Switch>
      </HashRouter>
    );

  }

}

// export
export default AuthClass;