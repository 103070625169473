const nav = {
  items: [
    {
      name: 'Customers',
      icon: 'far fa-user-crown',
      url: '/customers/overview',
    },
    {
      name: 'Enrollment',
      icon: 'fas fa-scroll',
      url: '/templates/overview',
    },
    {
      name: 'Users',
      icon: 'fad fa-mobile',
      url: '/users/overview',
    },
    {
      name: 'Connect',
      icon: 'fad fa-layer-group',
      url: '/connect/overview',
    },
    {
      name: 'Alarm Processes',
      icon: 'fas fa-stopwatch',
      url: '/tasks/process/overview',
    },
    {
      name: 'Log out',
      icon: 'fas fa-sign-out',
      url: '/session/logout',
      variant: 'danger',
      class: 'mt-auto'
    }
  ]
};

export default nav;